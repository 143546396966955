import { Button } from "@ream/ui";
import Link from "next/link";
import { Form as BSForm } from "react-bootstrap";
import { useAuth } from "src/state/AuthContext";
import { Account } from "src/types";
import { AppRoutes } from "src/util/appRoutes";

type AlreadyLoggedInProps = {
  user: Account;
};

export const AlreadyLoggedIn: React.FC<AlreadyLoggedInProps> = ({ user }) => {
  const { logout } = useAuth();
  return (
    <>
      <h4 className="mb-3">You are currently logged in as</h4>

      <BSForm.Group className="mb-3">
        <BSForm.Control value={user.email} readOnly disabled />
      </BSForm.Group>

      <Link href={AppRoutes.root()} passHref legacyBehavior>
        <Button>Go to Dashboard</Button>
      </Link>

      <Button variant="link" onClick={() => logout()}>
        Log Out
      </Button>
    </>
  );
};
