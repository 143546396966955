type LoginSidebarProps = {
  isAuthenticated: boolean;
  authenticatedMessage?: React.ReactNode;
  unauthenticatedMessage: React.ReactNode;
};
export const LoginSidebar: React.FC<LoginSidebarProps> = ({
  isAuthenticated,
  authenticatedMessage = "Welcome Back.",
  unauthenticatedMessage,
}) => {
  return (
    <div className="sidenav">
      <h1 className="logotype logotype--white">Ream.</h1>
      <p>{isAuthenticated ? authenticatedMessage : unauthenticatedMessage}</p>
    </div>
  );
};
