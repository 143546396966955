import { Alert } from "@ream/ui";
import Link from "next/link";
import React, { useMemo, useState } from "react";
import { Form as BSForm, Stack } from "react-bootstrap";
import { Field, Form, FormSpy, useField } from "react-final-form";
import { FormSubmitButton } from "src/components/form/FormSubmitButton";
import { TextField } from "src/components/form/TextField";
import { useTranslations } from "src/state/TranslationsContext";
import { useCreateMagicToken } from "src/util/api/authApi";
import { AppRoutes } from "src/util/appRoutes";
import { useLocalState } from "src/util/hooks/useLocalState";
import { composeValidators, requireEmail, required } from "src/util/validators";

const MagicLinkAuthFormFields = ({ onToggleMagicLink }) => {
  const field = useField("email");
  const { t } = useTranslations();

  return (
    <Stack gap={3}>
      <Field
        autoFocus
        type="email"
        name="email"
        label={t("client.login.email")}
        placeholder={t("client.login.placeholder")}
        suppressErrorUntilSubmitted
        component={TextField}
        validate={composeValidators(
          required(t("client.login.email_required")),
          requireEmail(t("client.login.email_required")),
        )}
        data-test="login-form-email"
      />

      <FormSubmitButton data-test="login-form-submit">
        {t("client.login.submit_magic_link")}
      </FormSubmitButton>

      <Alert variant="light" className="small">
        {t("client.login.magic_link_notice.0", {
          email: field.input.value,
          link: (
            <a
              data-test="login-form-use-password"
              className="alert-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onToggleMagicLink();
              }}
            >
              {t("client.login.magic_link_notice.1")}
            </a>
          ),
        })}
      </Alert>

      <Link className="small" href={AppRoutes.signup()}>
        {t("client.login.signup")}
      </Link>
    </Stack>
  );
};

export const MagicLinkAuthForm: React.FC<{ onToggleMagicLink: () => void }> = ({
  onToggleMagicLink,
}) => {
  const { t } = useTranslations();
  const [email, setLocalEmail] = useLocalState("login-email", "");
  const [doneFormData, setDoneFormData] = useState(false);

  const createToken = useCreateMagicToken();

  // We need to lock this value so that it's actually the first load
  // value, not the updating value, otherwise we screw with the form
  // state in react final form by updating the initial values to be
  // in sync with the current values.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialValues = useMemo(() => ({ email }), []);

  const onSubmit = async ({ email }) => {
    await createToken.mutateAsync({ email });
    setDoneFormData(true);
  };

  if (doneFormData) {
    return (
      <Alert variant="success">
        {t("client.login.magic_link_success.0", {
          email,
          link: (
            <a
              className="alert-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onToggleMagicLink();
              }}
            >
              {t("client.login.magic_link_success.1")}
            </a>
          ),
        })}
      </Alert>
    );
  }
  return (
    <Form onSubmit={onSubmit} validateOnBlur initialValues={initialValues}>
      {({ handleSubmit }) => {
        return (
          <BSForm onSubmit={handleSubmit}>
            <MagicLinkAuthFormFields onToggleMagicLink={onToggleMagicLink} />

            <FormSpy
              subscription={{ values: true }}
              onChange={({ values }) => {
                setLocalEmail(values.email);
              }}
            />
          </BSForm>
        );
      }}
    </Form>
  );
};
