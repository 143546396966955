import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useUpdateEffect } from "./useUpdateEffect";

export type LocalState = {
  "login-email": string;
  "auth-with-magic-link": boolean;
  "add-campaign-single": boolean;
  "add-campaign-recipient-submit-another": boolean;
  "ftue-complete": boolean;
};

export const useLocalState = <S>(
  key: keyof LocalState,
  initial: S,
): [S, Dispatch<SetStateAction<S>>] => {
  const localKey = `reamprefs.${key}`;

  const stored = useMemo(() => {
    const storedItem = localStorage.getItem(localKey);
    if (storedItem !== null) {
      try {
        return JSON.parse(storedItem) as any;
      } catch (err) {
        console.warn(
          "failed to parse stored local state item:",
          key,
          storedItem,
        );
      }
    }
    return initial;
  }, [key, localKey, initial]);

  const [val, setVal] = useState<S>(stored);

  useUpdateEffect(() => {
    localStorage.setItem(localKey, JSON.stringify(val));
  }, [val]);

  return [val, setVal];
};
