import { BasePageLayout } from "./BasePageLayout";
import { LoginSidebar } from "./LoginSidebar";

type LoginPageLayoutProps = {
  title?: string;
  isAuthenticated: boolean;
  children: React.ReactNode;
  authenticatedMessage?: React.ReactNode;
  unauthenticatedMessage: React.ReactNode;
};

export const LoginPageLayout: React.FC<LoginPageLayoutProps> = ({
  title,
  children,
  ...sidebarProps
}) => {
  return (
    <BasePageLayout title={title}>
      <section id="login">
        <main className="main">
          <div className="login_form">{children}</div>
        </main>

        <LoginSidebar {...sidebarProps} />
      </section>
    </BasePageLayout>
  );
};
