import { DependencyList, EffectCallback, useEffect, useRef } from "react";

/** Simliar to useEffect, except it will *not* be called for the initial value
 *
 * @example
 * const [foo, setFoo] = useState(1);
 * useUpdateEffect(() => console.log("useEffect:",foo), [foo]);
 * useUpdateEffect(() => console.log("useUpdateEffect:", foo), [foo]);
 * useEffect(() => setFoo(2), []);
 *
 * // output:
 * // useEffect: 1
 * // useEffect: 2
 * // useUpdateEffect: 2
 */
export const useUpdateEffect = (
  effect: EffectCallback,
  deps?: DependencyList,
): void => {
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      return effect();
    }
  }, deps);
};
