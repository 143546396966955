"use client";

import type { NextPage } from "next";
import { useEffect, useState } from "react";
import { LoginPageLayout } from "src/components/layout/LoginPageLayout";
import { AlreadyLoggedIn } from "src/components/login/AlreadyLoggedIn";
import { MagicLinkAuthForm } from "src/partials/login/MagicLinkAuthForm";
import { PasswordAuthForm } from "src/partials/login/PasswordAuthForm";
import { useAuth } from "src/state/AuthContext";
import { useTranslations } from "src/state/TranslationsContext";
import { useLocalState } from "src/util/hooks/useLocalState";

const LoginBody = () => {
  const { user, isAuthenticated } = useAuth();
  const { t } = useTranslations();

  const [magicLink, setMagicLink] = useLocalState("auth-with-magic-link", true);
  const handleToggleMagicLink = () => setMagicLink(!magicLink);

  const [showedPasswordForm, setShowedPasswordForm] = useState(false);

  useEffect(() => {
    if ((!isAuthenticated || !user) && !magicLink) {
      setShowedPasswordForm(true);
    }
  }, [isAuthenticated, user, magicLink]);

  if (isAuthenticated && user && !magicLink && !showedPasswordForm) {
    return <AlreadyLoggedIn user={user} />;
  }

  return (
    <>
      <h4 className="mb-3">{t("client.login.welcome")}</h4>

      {magicLink ? (
        <MagicLinkAuthForm onToggleMagicLink={handleToggleMagicLink} />
      ) : (
        <PasswordAuthForm onToggleMagicLink={handleToggleMagicLink} />
      )}
    </>
  );
};

const LoginPage: NextPage = () => {
  const { t } = useTranslations();
  const { isAuthenticated } = useAuth();

  return (
    <LoginPageLayout
      title={t("client.login.title")}
      isAuthenticated={isAuthenticated}
      unauthenticatedMessage={t("client.login.sidebar_welcome")}
    >
      <LoginBody />
    </LoginPageLayout>
  );
};

export default LoginPage;
