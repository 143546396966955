import { Button, LoadingCentered } from "@ream/ui";
import Link from "next/link";
import React, { useMemo } from "react";
import { Form as BSForm, Stack } from "react-bootstrap";
import { Field, Form, FormSpy } from "react-final-form";
import { FormSubmitButton } from "src/components/form/FormSubmitButton";
import { TextField } from "src/components/form/TextField";
import { useTranslations } from "src/state/TranslationsContext";
import { AppRoutes } from "src/util/appRoutes";
import { useLocalState } from "src/util/hooks/useLocalState";

import { Alert } from "@ream/ui";
import { useSearchParams } from "next/navigation";
import { useState } from "react";
import { useAuth } from "src/state/AuthContext";
import { extractErrorMessage } from "src/util/api/apiError";
import { useRedirect } from "src/util/redirect";
import { composeValidators, requireEmail, required } from "src/util/validators";

export type LoginFormValues = {
  email: string;
  password: string;
};

const PasswordAuthFormFields = ({ url, onToggleMagicLink }) => {
  const { t } = useTranslations();

  return (
    <Stack gap={3}>
      <Field
        autoFocus
        type="email"
        name="email"
        label={t("client.login.email")}
        placeholder={t("client.login.placeholder")}
        suppressErrorUntilSubmitted
        component={TextField}
        validate={composeValidators(
          required(t("client.login.email_required")),
          requireEmail(t("client.login.email_required")),
        )}
        data-test="login-form-email"
      />

      <Field
        label={t("client.login.password")}
        type="password"
        name="password"
        component={TextField}
        validate={required(t("client.login.password_required"))}
        data-test="login-form-password"
      />

      <Stack direction="vertical" gap={3}>
        <FormSubmitButton data-test="login-form-submit">
          {t("client.login.submit")}
        </FormSubmitButton>

        <Link className="small" href={AppRoutes.signup()}>
          {t("client.login.signup")}
        </Link>

        <Link className="small" href={url}>
          {t("client.login.need_reset")}
        </Link>

        <Button
          type="button"
          variant="link"
          className="p-0 align-self-start"
          size="sm"
          onClick={onToggleMagicLink}
        >
          {t("client.login.magic_link")}
        </Button>
      </Stack>
    </Stack>
  );
};

export const PasswordAuthForm: React.FC<{
  onToggleMagicLink: () => void;
}> = ({ onToggleMagicLink }) => {
  const { t } = useTranslations();
  const { login } = useAuth();

  const [email, setLocalEmail] = useLocalState("login-email", "");

  const url = useMemo(() => AppRoutes.forgotPassword({ email }), [email]);

  // We need to lock this value so that it's actually the first load
  // value, not the updating value, otherwise we screw with the form
  // state in react final form by updating the initial values to be
  // in sync with the current values.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialValues = useMemo(() => ({ email }), []);

  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const redirect = useRedirect();
  const sp = useSearchParams();
  const next = sp.get("next");

  const onSubmit = async (formData: LoginFormValues) => {
    const { email, password } = formData;
    setError(null);

    try {
      await login(email, password);
      setSuccess(true);

      redirect(next ?? AppRoutes.root());
    } catch (error: any) {
      setError(
        extractErrorMessage(error, t("client.login.error_during_login")),
      );
    }
  };

  if (success) {
    return <LoadingCentered />;
  }

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}

      <Form onSubmit={onSubmit} initialValues={initialValues}>
        {({ handleSubmit }) => {
          return (
            <BSForm onSubmit={handleSubmit}>
              <PasswordAuthFormFields
                url={url}
                onToggleMagicLink={onToggleMagicLink}
              />

              <FormSpy
                subscription={{ values: true }}
                onChange={({ values }) => {
                  setLocalEmail(values?.email);
                }}
              />
            </BSForm>
          );
        }}
      </Form>
    </>
  );
};
